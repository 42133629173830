import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";


//提交
export function login(params) {
	return request({
		url: baseUrl + "/spider/web/login",
		method: "post",
		data: params
	});
}