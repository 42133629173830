<template>
	<div id="login">
		<!-- 登录页 -->
		<div class="back"></div>

		<div class="loginBox">
			<div class="img">
				<!-- <img src="../../assets/images/pansihole.svg" alt=""> -->
				<img src="../../assets/images/logo.svg" alt="">
				<i>设计作品交付及文档管理</i>
			</div>
			<div class="box">
				<div>登录</div>
				<div class="userId">
					<p>用户名</p>
					<el-input v-model="username" placeholder="请输入授权的用户名"></el-input>
				</div>
				<div class="password">
					<p>密码</p>
					<el-input v-model="passWord" placeholder="请输入您的密码" show-password @change="loginFun()"></el-input>
				</div>
				<div class="opt">
					<el-checkbox v-model="remember">30天内免登录</el-checkbox>
					<b class="fun" @click="alertFun">忘记密码</b>
				</div>
				<el-button class="loginBtn" @click="loginFun()">登录</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		login
	} from '@/request/apiLogin.js';
	export default {
		name: 'login',
		data() {
			return {
				username: "",
				passWord: '',
				loginType: true,
				remember: false
			}
		},
		created() {
			this.rememberLogin();
		},
		mounted() {},
		methods: {
			// 30填免登录
			rememberLogin() {
				let remember = localStorage.getItem('remember')
				if (remember) {
					remember = JSON.parse(remember);
					if (remember.timeOut > Date.now()) {
						this.username = remember.I;
						this.passWord = remember.P;
						this.loginFun();
					}
				}
			},

			alertFun() {
				console.log(1)
				this.$alert(`请联系管理员重置密码,<br/>联系电话:18631886228`, '提示', {
					confirmButtonText: '确定',
					dangerouslyUseHTMLString: true,
					closeOnClickModal: true,
					closeOnPressEscape: true
				});
			},

			loginFun() {
				if (this.loginType) {
					this.loginType = false;
					let data = {};
					data.username = this.username;
					data.password = this.passWord;
					login(data).then(res => {
						if (res.code == 200) {
							// console.log(res)
							localStorage.setItem('headToken', res.data.token);
							localStorage.setItem('timeOut', Date.now());

							if (this.remember) {
								localStorage.setItem('remember', JSON.stringify({
									I: this.username,
									P: this.passWord,
									timeOut: Date.now() + 1000 * 60 * 60 * 24 * 30
								}))
							};

							this.$notify({
								title: '登录成功',
								message: '您已登陆成功。',
								type: 'success',
								position: 'bottom-right'
							});

							this.$router.push({
								name: 'NetDisk',
							});
						} else {
							this.$notify.error({
								title: '错误',
								message: res.msg,
								position: 'bottom-right',
							});
						}
						this.loginType = true;
					}).catch(err => {
						console.log(err)
						this.loginType = true;
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	#login {
		height: 100vh;
		overflow: hidden;
		background-image: linear-gradient(30deg, #625bff, #c548ab);
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		.back {
			position: absolute;
			left: 0;
			top: 40vh;
			width: 60vh;
			height: 60vh;
			border: 40vh solid rgba(255, 255, 255, .1);
			border-radius: 50%;
			transform: translate(-50%, -50%);
			box-sizing: content-box;
		}

		.loginBox {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			.img {
				flex: 1;
				color: #fff;
				margin-right: 50px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				img {
					margin-bottom: 20px;
				}

				i {
					font-style: inherit;
					font-size: 34px;
				}
			}

			.box {
				background-color: #fff;
				width: 340px;
				border-radius: 10px;
				padding: 20px 40px 40px;
				display: flex;
				flex-direction: column;

				>div {
					font-size: 40px;
					text-align: center;
				}

				.opt {
					display: flex;
					justify-content: space-between;
					margin-top: 10px;

					.fun {
						font-size: 14px;
						font-weight: normal;
					}

					/deep/.el-checkbox {
						font-weight: normal;
						font-size: 14px;
					}
				}

				.userId,
				.password {
					display: flex;
					flex-direction: column;
					margin-top: 22px;
					font-size: 22px;
					font-weight: 400;

					* {}

					p {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						font-size: 16px;

						.fun {
							font-size: 14px;
							font-weight: normal;
							color: #682EDC;
						}
					}

					.el-input {
						margin-top: 6px;
					}
				}

				.loginBtn {
					margin-top: 40px;
					background-image: linear-gradient(30deg, #625bff, #c548ab);
					border: none;
					font-size: 22px;
					color: #fff;
					border-radius: 10px;
					font-weight: 400;
				}
			}
		}
	}
</style>